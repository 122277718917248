@use "mixins" as *
.bonuses
  padding: 0 8px
  display: flex
  flex-direction: column
  flex: 1
  :global(.no-data)
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)

.header
  @include accountStickyHeader

.back
  @include backArrowBtn

.content
  flex: 1
  position: relative
  padding-bottom: var(--menuHeight)